import React from 'react';
import { useParams } from 'react-router-dom';
import ModalsList from './modalsList';



function Main3DPage() {
    let {MID} = useParams()
    return ( <>
    <div className='border-0 shadow-sm m-0 rounded-0 text-danger pt-3 ps-3 align-self-center fixed-top bg-white ' style={{height: 60}}><h1>{ModalsList[MID].name}</h1></div>
     <div className='container'>
        <br />
        <br />
        <br />
        <br />
        <div className='mt-2 mb-4'>
            <img src={ModalsList[MID].imgSource} className='img-responsive border-div' width="100%" height='auto' />
        </div> 
        <div className='mt-2 mb-4'><h1>{ModalsList[MID].name}</h1></div> 
        <div className='mt-2 mb-4'><h5 className='text-start text-secondary'>{ModalsList[MID].description}</h5></div> 
        <br />
        <br />
     </div>
    </> );
}

export default Main3DPage;