import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Outlet} from "react-router-dom";
import GConf from '../generalConf';

//navBar
import NavBar from '../../Dashboard/navBar'
import LeftSideCard from '../../Dashboard/leftSide';

//Main
import MainPage from '../../Dashboard/Main/mainPage';


//Communication
import RequestPage from '../../Dashboard/Communications/communicationPage';
import RequestInfo from "../../Dashboard/Communications/communicationInfo";
import RequestTable from '../../Dashboard/Communications/communicationTable';
import LatestRequest from '../../Dashboard/Communications/latestRequest';

//Directory
import DirectoryPage from "../../Dashboard/Directory/directoryPage";
import DirectoryTable from "../../Dashboard/Directory/directoryTable";
import InfoFromDirectory from "../../Dashboard/Directory/infoFromDirectory";
import AjoutreToDirectory from "../../Dashboard/Directory/ajoutreToDirectory";
import DemmandeAjoutre from "../../Dashboard/Directory/clientRequest";
import DemmandeAjoutreInfo from "../../Dashboard/Directory/clientRequestInfo";

import EditFromDirectory from '../../Dashboard/Directory/editFromDirectory';
import DirectoryImage from '../../Dashboard/Directory/directoryImage';
import DirectoryPosition from '../../Dashboard/Directory/directoryPosition';
import DirectoryActivation from '../../Dashboard/Directory/directoryActivation';


//System
import SystemPage from '../../Dashboard/System/systemPage';
import SystemInfo from "../../Dashboard/System/systemInfo";
import SystemTable from "../../Dashboard/System/systemTable";
import SystemTableInfo from '../../Dashboard/System/systemTableInfo';

//Outils
import ToolsPage from '../../Dashboard/Tools/toolsPage';
import TaxiPage from '../../Dashboard/Tools/TaxiPage';
import LouagePage from '../../Dashboard/Tools/LouagePage';
import BlogPage from '../../Dashboard/Tools/BlogPage';
import RentPage from '../../Dashboard/Tools/RentPage';
import AgriToolsPage from '../../Dashboard/Tools/AgriToolsPage';
import NewsPage from '../../Dashboard/Tools/NewsPage';

//USER 
import UserPage from '../../Dashboard/Users/userPage'
import AjouterUser from "../../Dashboard/Users/ajouterUser";
import UserInfo from '../../Dashboard/Users/userInfo'
 

//Client
import ClientPage from '../../Dashboard/Client/clientPage';
import AjouterClient from "../../Dashboard/Client/ajouterClient";
import ClientInfo from '../../Dashboard/Client/clientInfo'
import ClientRequest from '../../Dashboard/Client/clientRequest';
import ClientRequestInfo from "../../Dashboard/Client/clientRequestInfo";
import ClientActivation from "../../Dashboard/Client/clientActivation";
import ClientActivationInfo from "../../Dashboard/Client/clientActivationInfo";


//Team 
import TeamPage from '../../Dashboard/Team/teamPage';
import AjouterTeam from '../../Dashboard/Team/ajouterTeam';
import TeamPoste from '../../Dashboard/Team/teamPoste';
import TeamDemande from '../../Dashboard/Team/demandeTeam';
import TeamInfo from '../../Dashboard/Team/teamInfo';
import TeamAvance from '../../Dashboard/Team/teamAvance';


//OTHERS
import OtherPage from '../../Dashboard/Others/otherPage';
import ServeurPage from '../../Dashboard/Others/serveurPage';
import BackUpPage from '../../Dashboard/Others/backUpPage';
import ConcurantPage from '../../Dashboard/Others/concurantPage';
import RelationPage from '../../Dashboard/Others/relationPage';
import MessagesPages from '../../Dashboard/Messages/messagesPage'
import MarketingPage from '../../Dashboard/Others/marketingPage';
import PlanningPage from '../../Dashboard/Others/planningPage';
import FinancePage from '../../Dashboard/Others/financePage';
import StatPage from '../../Dashboard/Others/statPage';
import VentesPage from '../../Dashboard/Others/ventePage';
import ProfilePID from '../../Dashboard/Directory/printPID';

//Houssem
import HoussemCvPage from '../../Dashboard/Houssem/cvPage';
import Main3DPage from '../../Dashboard/Tools/3dM/manPage';
import Main3DPageInfo from '../../Dashboard/Tools/3dM/manPageInfo';





const SystemLanding = () => {
    useEffect(() => {
        CheckLogIn()
    },[]);
    const CheckLogIn = () =>{
        const pidIsSet = localStorage.getItem('ADMIN_ID');
        if (!pidIsSet) {window.location.href = "/login";}
    }

    return (<>
        <NavBar/>
        <br />
        <br />
        <br />
        <div className='row pt-4 m-1'>
                <div className='col-12 col-md-12 col-lg-2'><LeftSideCard /></div>
                <div className='col-12 col-md-12 col-lg-10'><Outlet /></div>
        </div>
    </>);
}

const systemRouter = () => (<>
        <Route path="hsm/cv" exact element={<HoussemCvPage />} />
        <Route path="S" exact element={<SystemLanding />} >
            <Route path="" exact element={<MainPage />} />
            <Route path="ma" exact element={<MainPage />} />
            <Route path="rq" exact element={<Outlet />} >
                <Route path="" exact element={<RequestPage />} />
                <Route path="table/:genre" exact element={<RequestTable />} />
                <Route path="info/:systemTag/:TAG/:CID" exact element={<RequestInfo />} />
                <Route path="latest" exact element={<LatestRequest />} />

            </Route>
            <Route path="an" exact element={<Outlet />}>
                <Route path="" exact element={<DirectoryPage />} />
                <Route path="table/:genre" exact element={<DirectoryTable />} />
                <Route path="info/:genre/:PID" exact element={<InfoFromDirectory />} />
                <Route path="ajouter" exact element={<AjoutreToDirectory />} />
                <Route path="demmande" exact element={<DemmandeAjoutre />} />
                <Route path="demmande/info/:ID" exact element={<DemmandeAjoutreInfo />} />
                
            </Route>
            <Route path="sy" exact element={<Outlet />}>
                <Route path="" exact element={<SystemPage />} />
                <Route path="info/:code" exact element={<SystemInfo />} />
                <Route path="tables/:genres/:table" exact element={<SystemTable />} />
                <Route path="tables/info/:genres/:table" exact element={<SystemTableInfo />} />
            </Route>
            <Route path="us" exact element={<Outlet />}>
                <Route path="" exact element={<UserPage />} />
                <Route path="ajouter" exact element={<AjouterUser />} />
                <Route path="info/:UID" exact element={<UserInfo />} />
            </Route>
            <Route path="cl" exact element={<Outlet />} >
                <Route path="" exact element={<ClientPage />} />
                <Route path="ajouter" exact element={<AjouterClient />} />
                <Route path="info/:CLID" exact element={<ClientInfo />} />
                <Route path="demande" exact element={<ClientRequest />} />
                <Route path="demande/info/:RID" exact element={<ClientRequestInfo />} />
                <Route path="activation" exact element={<ClientActivation />} />
                <Route path="activation/info/:ID" exact element={<ClientActivationInfo />} />
            </Route>
            <Route path="tm" exact element={<Outlet />} >
                <Route path="" exact element={<TeamPage />} />
                <Route path="info/:TID" exact element={<TeamInfo />} />
                <Route path="ajouter" exact element={<AjouterTeam />} />
                <Route path="postes" exact element={<TeamPoste />} />
                <Route path="avances" exact element={<TeamAvance />} />
                <Route path="presence" exact element={<TeamDemande />} />
            </Route>
            <Route path="ot" exact element={<Outlet />} >
                <Route path="" exact element={<ToolsPage />} />
                <Route path="taxi" exact element={<TaxiPage />} />
                <Route path="louage" exact element={<LouagePage />} />
                <Route path="renting" exact element={<RentPage />} />
                <Route path="agritools" exact element={<AgriToolsPage />} />
                <Route path="news" exact element={<NewsPage />} />
                <Route path="blog" exact element={<BlogPage />} />
            </Route>
            <Route path="th" exact element={<Outlet />} >
                <Route path="marketing" exact element={<MarketingPage />} />
                <Route path="" exact element={<OtherPage />} />
                <Route path="serveur" exact element={<ServeurPage />} />
                <Route path="backup" exact element={<BackUpPage />} />
                <Route path="messages" exact element={<MessagesPages />} />
                <Route path="concurrent" exact element={<ConcurantPage />} />
                <Route path="relation" exact element={<RelationPage />} />
                <Route path="ventes" exact element={<VentesPage />} />
                <Route path="planning" exact element={<PlanningPage />} />
                <Route path="finance" exact element={<FinancePage />} />
                <Route path="stat" exact element={<StatPage />} />
            </Route>
             
            
             
        </Route>
        <Route path="3dmodals" exact element={<Main3DPage />} />
        <Route path="3dmodals/:MID" exact element={<Main3DPageInfo />} />
        <Route path="print/:genre/:PID" exact element={<ProfilePID />} />
        </>
        
)

export default systemRouter 