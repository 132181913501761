const ModalsList = {
   lion:{
    imgSource:'https://t3.ftcdn.net/jpg/05/69/30/42/360_F_569304262_RGVohUth9wyR5Msa3CoR4XFvMYE8VG1k.jpg',
    name:'LE LION ',
    description : "Le lion est un grand félin majestueux, souvent surnommé le (roi des animaux). Doté d'une crinière impressionnante chez les mâles, il arbore une allure puissante et noble. Cet animal vit en groupes sociaux appelés *troupeaux* ou *prides*, principalement en Afrique subsaharienne. Prédateur redoutable, il chasse des proies comme les zèbres et les antilopes. Le lion est également connu pour ses rugissements puissants, qui peuvent être entendus à plusieurs kilomètres. Sa fourrure varie du jaune clair au brun doré, et les petits naissent tachetés. Symbolisant le courage et la force, le lion est un animal emblématique dans de nombreuses cultures. Il est cependant menacé par la perte de son habitat et la chasse illégale."
   },
   avion:{
    imgSource:'https://actualites.uqam.ca/wp-content/uploads/2022/01/avion-gettyimages-912360406-w.jpg',
    name:'L\'AVION',
    description : "L’avion est un moyen de transport aérien conçu pour voler grâce à ses ailes et son moteur. Il permet de parcourir de longues distances en un temps réduit, reliant des villes et des pays à travers le monde. Fabriqué en matériaux légers et résistants, il peut transporter des passagers, du fret, ou même des secours d’urgence. Les avions modernes utilisent des technologies avancées pour assurer sécurité et efficacité. Ils fonctionnent grâce à des turbines ou des hélices et nécessitent des pistes pour décoller et atterrir. Symbolisant le progrès et la liberté de déplacement, l’avion a révolutionné les voyages et le commerce mondial."
   },
   giraffe:{

   }  
}
export default ModalsList