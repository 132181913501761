import React from 'react';
import QRCode from "react-qr-code";
function CvPage() {
    const QRCodeCardSystem = (props) => {
        return(
            <div className='row col-10'>
                    <div className='col-4 align-self-center  '>
                        
                        <QRCode   value={props.link} size={120} />
                    </div>
                    <div className='col-8 text-start'>
                         
                         {
                            props.appLink ? <b> {props.appLink} </b>: 
                            <>
                                <h5 className='mb-1'>Live Demo</h5>
                                <a href={props.link}>{props.link}</a>
                                
                            </>

                         }
                         
                         <br />
                         <br />
                         CREDENTIALS :
                         <div className='row'>
                            <div className='col-6 algin-self-center'>
                                <div className='border-div p-2   mb-3' style={{backgroundColor: '#ccc9c6'}}>
                                    ID : {props.id}
                                </div>
                            </div>
                            <div className='col-6 algin-self-center'>
                                <div className='border-div p-2  ' style={{backgroundColor: '#ccc9c6'}}>
                                    PWD : {props.pwd}
                                </div>
                            </div>
                         </div>
                         
                         
                    </div> 
                </div>
            
        )
    }
    return (  <>
        
          
        <div style={{backgroundColor: '#d1d1d1'}} className='mb-4 p-3'>
            <div className='row '>
                <div className='col-8'>
                    <h1 className='display-3' style={{color:'#d4282b'}}>KHELIFI HOUSSEM</h1> 
                    <h3 className='text-secondary mt-0'>FULL-STACK WEB DEVOLOPER</h3>
                </div>
                <div className='col-4'>
                    <div><span className='bi bi-whatsapp bi-sm' style={{color:'#d4282b'}}></span> : +216 96787676</div>
                    <div><span className='bi bi-envelope-at bi-sm' style={{color:'#d4282b'}}></span> : khelifihoussem53@gmail.com</div>
                    <div><span className='bi bi-geo-alt bi-sm' style={{color:'#d4282b'}}></span> : Ben Arous , Tunisia</div>
                    <div><span className='bi bi-linkedin bi-sm' style={{color:'#d4282b'}}></span> : houssem-khelifi-66950016b/</div>
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='mb-4 mt-3'>
                <h5 style={{color:'#d4282b'}}>DESCRIPTION</h5>
                <hr />
                <p>
                Passionate full-stack developer with experience building web applications and ERP systems.
                <br />
                Skilled in React, Node.js, MySQL, and tools like Grid.js, Leaflet, and Firebase, with projects spanning industries such as gyms, hotels, and transportation.
                <br />
                My commerce background sharpened my skills in client communication and problem-solving, enabling me to deliver user-focused solutions and collaborate effectively with stakeholders.
                </p>
            </div>
            <div className='mb-4 mt-3'>
                <h5 style={{color:'#d4282b'}}>KEY SKILLS</h5>
                <hr />
                <div className='row'>
                    <div className='col-6'>
                        <ul>
                            <li>JavaScript, React.js, Node.js, SQL</li>
                            <li>REST APIs, Firebase, PWA development</li>
                            <li>Git/GitHub, Agile methodologies</li>
                            <li>Grid.js, Leaflet, Bootstrap, Semantic UI...</li>
                        </ul>
                    </div>
                    <div className='col-6'>
                        <ul>
                            <li>Leadership</li>
                            <li>Client Communication</li>
                            <li>Team Collaboration</li>
                            <li>Problem-Solving</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='mb-4 mt-3'>
                <h5 style={{color:'#d4282b'}}>LANGUAGES</h5>
                <hr />
                <div className='row'>
                    <div className='col-6'>
                         <h5 className='ms-3'>ENGLISH : fluent</h5>
                         <div class="progress" style={{height:7}}>
                            <div class="progress-bar " style={{width: '80%', background:'#c94d4f'}}> </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <h5 className='ms-3'>FRANCAIS : courante</h5>
                         <div class="progress" style={{height:7}}>
                            <div class="progress-bar " style={{width: '70%', background:'#c94d4f'}}> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-4 mt-3'>
                <h5 style={{color:'#d4282b'}}>PROJECTS</h5>
                <hr />
                <b>SEP 2021 -- JAN 2022 : Building  Doctor Management System</b>
                <div className='ms-5 text-start mb-3'>
                    <div>
                        Developed using React, Node.js, Express, and SQL to manage patient records and appointments. 
                        <br />
                        Integrated Draft.js for patient notes, react-qr-code for patient IDs, and Firebase Messaging to send appointment notifications. Supports multiple languages with i18next.
                    </div>
                </div>
                <QRCodeCardSystem link='https://doctor.system.abyedh.com/' id='96787676' pwd ='doct123' />
                 <br />
                 <br />
                 
                 
                <b>JAN 2022 -- MAY 2022 : Building  Driving School Management System </b>
                <div className='ms-5 text-start mb-3'>
                    Designed using React, Node.js, Express, and SQL to manage driving lessons and student progress. 
                    <br />
                    Implemented react-leaflet to visualize and record lessons on maps for tracking purposes.
                </div>
                {/* <div className='breack-page-here'></div> */}
                 
                <QRCodeCardSystem link='https://driving-school.system.abyedh.com/' id='96787676' pwd ='auto123' />

                <br />
                <br />
                
                <b>MAY 2022 -- OCT 2022 : Building Gym Management System </b>
                <div className='ms-5 text-start mb-3'>                    
                        Developed using React, Node.js, Express, and SQL to track memberships and groups attendance. 
                        <br />
                        Integrated FullCalendar.js for groups schedules, react-qr-code to save attendance, and Firebase Messaging for member notifications.
                </div>
                <QRCodeCardSystem link='https://gym.system.abyedh.com/' id='96787676' pwd ='gym123' />

                <br />
                <br />
                
                <b>OCT 2022 -- AVR 2023 : Building Daycare Management System </b>
                <div className='ms-5 text-start mb-3'>
                        Built with React, Node.js, Express, and SQL to organize lessons and track student attendance. 
                        <br />
                        Used FullCalendar.js for lesson schedules, react-qr-code for student tracking, and Firebase Messaging to notify parents.
                </div>
                <QRCodeCardSystem link='https://daycare.system.abyedh.com/' id='96787676' pwd ='gard123' />

                <br />
                <br />
                
                <b>AVR 2023 -- OUT 2023 : Building Restaurant Management System </b>
                <div className='ms-5 text-start mb-3'>
                    Created with React, Node.js, Express, and SQL to handle reservations and menu management. 
                     <br />
                    FullCalendar.js was used for booking schedules, with Imgur API for uploading menu images.

                </div>
                <QRCodeCardSystem link='https://restaurant.system.abyedh.com/' id='96787676' pwd ='resto123' />

                <div className='d-none'>
                <br />
                <br />
                <br />
                <b>OUT 2023 -- DEC 2023 : Building Transporter Management System </b>
                <div className='ms-5 text-start mb-3'>
                    Built a platform for managing vehicle routes, cargo tracking, and driver assignments.
                    <br />
                    Technologies Used: React.js, Express.js, Node.js, MySQL, Leaflet, react-leaflet.
                    Integrated maps to visualize routes and real-time cargo locations.
                    Enabled driver check-ins with QR code generation using react-qr-code.
                    Implemented Firebase notifications for delivery updates and alerts.

                </div>
                <QRCodeCardSystem link='https://transporter.system.abyedh.com/' id='96787676' pwd ='trans123' />

                <br />
                <br />
                <b>DEC 2023 -- JAN 2024 : Building Hotel Management System </b>
                <div className='ms-5 text-start mb-3'>
                    Developed a web app to manage room bookings, guest check-ins, and services.
                    <br />
                    Technologies Used: React.js, Node.js, Express.js, MySQL, Calendar.js, Firebase.
                    Integrated QR codes for contactless check-in and room access.
                    Managed customer feedback and reviews with real-time updates via Firebase.
                    Added multi-language support for international guests using i18next.

                </div>
                <QRCodeCardSystem link='https://hotel.system.abyedh.com/' id='96787676' pwd ='hot123' />
                </div>
                <br />
                <br />
                <b> 2020 -- NOW : Building SUPPER APP  abyedh.com </b>
                <div className='ms-5 text-start mb-3'>
                        Developed using React, MySQL, Express, and tools like Grid.js, FullCalendar, Firebase, QR-code, React-Leaflet, i18next (for multilingual support), Imgur API, Bootstrap, and Semantic UI. 
                        Abyedh.com is designed to streamline daily tasks and aims to become a global super-app by offering booking services across multiple sectors, including health, education, transportation, and finance. 
                        The platform allows users to schedule appointments with healthcare professionals, book taxis, reserve tables at restaurants, and more, all from a single interface.

                </div>
                <QRCodeCardSystem appLink='abyedh.com : PWA' link='https://play.google.com/store/apps/details?id=tn.abyedh.twa&hl=fr' id='26671342' pwd ='abyedh2018' />
                <br />
                <br />
                <b> MAR 2022 -- JUIN 2022 : Building kallax.fr App   </b>
                <div className='ms-5 text-start mb-3'>
                    Developed with React, MySQL, Express, using Material UI and Stripe API. 
                    Kallax streamlines service bookings in France, connecting users with electricians, plumbers, and other professionals, offering secure payments.
                </div>
                <QRCodeCardSystem appLink='kallax-s.fr PWA' link='https://play.google.com/store/apps/details?id=tn.ansl.kallax.twa' id='Houssem' pwd ='123456' />
                
                <h5 style={{color:'#d4282b'}}>OTHER PROJECTS</h5>
                <hr />

                <h5>Shop Management System </h5>
                <QRCodeCardSystem link='https://shop.system.abyedh.com/' id='96787676' pwd ='mg123' />
                
                <h5>Hotels Management System </h5>
                <QRCodeCardSystem link='https://hotel.system.abyedh.com/' id='96787676' pwd ='hot123' />
                
                <h5>Transporteur Management System </h5>
                <QRCodeCardSystem link='https://transporter.system.abyedh.com/' id='96787676' pwd ='trans123' />
            </div>


        </div>
    </>);
}

export default CvPage;